import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import tw from 'tailwind.macro';
import {
  FaArrowsAlt,
  FaBalanceScale,
  FaBed,
  FaBriefcaseMedical,
  FaChartPie,
  FaCity,
  FaCog,
  FaDove,
  FaGraduationCap,
  FaHandHoldingUsd,
  FaLandmark,
  FaLeaf,
  FaPeopleCarry,
  FaRegChartBar,
  FaTabletAlt,
  FaTractor,
} from 'react-icons/fa';
import Button from '../components/button';
import Grid from '../components/grid';
import Layout from '../components/layout';
import LeadText from '../components/leadText';
import Masthead from '../components/masthead';
import Section from '../components/section';
import SEO from '../components/seo';
import PageNavigator from '../components/pageNavigator';
import Panel from '../components/panel';
import SafeLink from '../components/safeLink';

const Summit2024 = ({ data, location }) => {
  const parentTitle =
    'The Centre for Social Justice presents:';
  const pageTitle =
    'The 6th Annual Social Justice Summit';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:30vh;`]}
      />
      <PageNavigator
        items={{
          welcome: 'Welcome',
          concept: 'Concept',
        // resources: 'Presenter resources',
          booklet: 'Booklet',
          media: 'Media coverage',
          //registration: 'Registration',
          watch: 'Watch',
          contact: 'Contact us',
          //   /* press: 'Press coverage', */
          }}
          buttonText="Read statement"
          buttonLink="/blog/2024/10/participants-pledge-united-action-against-hunger-at-international-social-justice-conference-and-summit"       
        />
      {/* <div className="container" css={tw`mt-10 px-10 py-5 rounded-lg border-t-8 border-gold bg-white shadow-lg`}>
        <LeadText css={tw`mb-0`}>Nominations for the 2024 Social Justice Champion Awards are now open! <SafeLink to="/downloads/events/2024-08-social-justice-champion-awards.pdf" css={tw`border-b-2 border-maroon hover:text-maroon`}>Click here to submit nominations.</SafeLink></LeadText>
      </div> */}
      <Section id="welcome">
        <Img
          fluid={data.summit2024Banner.childImageSharp.fluid}
          alt="The 6th Social Justice Summit, 17-18 October 2024"
          css={tw`w-full`}
        />
        <hr css={tw`mt-3 mb-10`} />
        <h2>
        Welcome!
        </h2>                       
        {/*
        <Button
          to="https://qkt.io/QICEvA "
          size="md"
          css={tw`mb-2 mr-2 bg-dark-gray`}
        >
          Pay your ticket
        </Button> 
        <Button
          to="/downloads/events/2024-10-call-for-papers.pdf"
          size="md"
          css={tw`mb-2 mr-2`}
        >
          Download call for abstracts
        </Button>
        <Button
          to="/downloads/events/2024-10-call-for-papers.pdf"
          size="md"
          css={tw`mb-2 mr-2`}
        >
          Download call for abstracts
        </Button>*/}      
        <Button
          to="https://twitter.com/CFSJ_MPlan"
          size="md"
          css={tw`mb-2 mr-2 bg-dark-gray`}
        >
          Follow updates
        </Button> <Button
          to="/blog/2024/10/participants-pledge-united-action-against-hunger-at-international-social-justice-conference-and-summit"
          size="md"
          css={tw`mb-2 mr-2`}
        >
          Read statement
        </Button>
        <br/>       
        <p>The 6th Social Justice Summit will take place 17-18 October 2024 at Artscape Theatre Centre, Cape Town, South Africa. <SafeLink to="/downloads/events/2024-08-summit-poster.pdf" css={tw`border-b-2 border-maroon hover:text-maroon`}>Download the summit poster &rarr;</SafeLink></p>
      </Section>
      <Section id="concept" isShaded>
        <h2>Concept</h2>
        <p>In October 2023, the International Conference and Summit on Social Justice resolved that “Hunger” would be the focus of the activities at the Centre for Social Justice (CSJ) and the Musa Plan for Social Justice (Musa Plan) in 2024. Hunger (SDG2) is one of the world’s biggest challenges. Despite remarkable technological advances globally, hunger remains a worldwide crisis, impacting millions of people every day. Hunger is not just about the absence of food — it is about a lack of access to adequate nutrition, health services, and clean water, chronic disparities in wealth and gender, and displacement due to conflict and climate change.</p>
        <Button
          to="/downloads/events/2024-06-summit-concept-note.pdf"
          size="md"
          css={tw`mb-2 mr-2 bg-dark-gray`}
        >
          Download concept note
        </Button> <Button
          to="/downloads/events/2024-08-summit-compendium.pdf"
          size="md"
          css={tw`mb-2`}
        >
          Download compendium
        </Button>
        
      </Section>
      <Section id="booklet">
        <h2>Booklet</h2>
        <p>Download the Summit booklet, with all information, here:</p>
        <Button
          to="/downloads/events/2024-11-final-social-justice-summit-booklet.pdf"
          size="lg"
          css={tw`mb-2 mr-2`}
        >
          Download now (PDF, 3MB)
        </Button>
      </Section>      
     {
      /*<Section id="resources" isShaded>
        <h2>Presenter resources</h2>
        <p>The call for abstracts is now closed. Please note the following important dates:</p>
        <ul>
          <li>Deadline for submission of PowerPoint Presentations to fanele@sun.ac.za: 09 October 2024</li>
          <li>Deadline for submission of the draft paper: 11 October 2024</li>
          <li>Deadline for submission of the final paper: 15 November 2024</li>
          <li>Deadline for review and approval of the final paper by the Organising Committee: 30 November 2024</li>
        </ul>
        <br/>
        <Button
          to="/downloads/events/2024-07-SocialJustice_InternationalConference&Summit_Paper&PresentationGuidelines_03July2024versionF.pdf"
          size="md"
          css={tw`mb-2 mr-2`}
        >
          Download guidelines
        </Button> <Button
          to="/downloads/events/conference-summit-2024-poster-tips-20240724.pdf"
          size="md"
          css={tw`mb-2 mr-2 bg-dark-gray`}
        >
          Download poster tips
        </Button> <Button
          to="/downloads/events/conference-summit-2024-ppt-template.pptx"
          size="md"
          css={tw`mb-2 mr-2 bg-dark-gray`}
        >
          Download presentation template
        </Button>  
      </Section>
      <Section id="programme">
        <h2>Programme</h2>                   
        <Button
          to="/downloads/events/conference-summit-2024-summit-programme.pdf"
          size="md"
          css={tw`mb-2 mr-2`}
        >
          Download summit programme
        </Button> <Button
          to="/downloads/events/conference-summit-2024-conference-programme.pdf"
          size="md"
          css={tw`mb-2 mr-2  bg-dark-gray`}
        >
          Download conference programme
        </Button>
        <p>We're pleased to announce the programme directors:</p>
        <Grid mdGridCols={2} lgGridCols={2}>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.basetsanaKumalo.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Basetsana Kumalo</h3>
            <LeadText>
              Board Leader, Chairperson, CEO, Entrepreneur, Author, Director, TV Producer, Investor and Philanthropist
            </LeadText>   
            <Button
              to="/downloads/events/2024-07-basetsana-julia-kumalo-biography.pdf"
              size="md"
              css={tw`mb-2 mr-2 bg-dark-gray`}
            >
              Download biography
            </Button>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.songezoMabece.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Songezo Mabece</h3>
            <LeadText>
              Lawyer with specialisations in legal advisory and research, public policy, and regulatory work
            </LeadText>
            <Button
              to="/downloads/events/2024-07-songezo-mabece.pdf"
              size="md"
              css={tw`mb-2 mr-2 bg-dark-gray`}
            >
              Download biography
            </Button>           
          </div>
        </Grid>
      </Section>*/ }
      <Section id="media" isShaded>
        <h2>Media Coverage and more</h2>        
        <ul>          
          <li><SafeLink to="/blog/2024/10/participants-pledge-united-action-against-hunger-at-international-social-justice-conference-and-summit" css={tw`border-b-2 border-maroon hover:text-maroon`}>Official statement</SafeLink></li>
          <li><SafeLink to="https://www.universityworldnews.com/post.php?story=2024102207030187" css={tw`border-b-2 border-maroon hover:text-maroon`}>Coverage by World University News</SafeLink></li>
          <li><SafeLink to="https://foodsecurity.ac.za/news/join-us-at-the-5th-international-social-justice-conference/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Coverage by Centre of Excellence in Food Security</SafeLink></li>
        </ul>
      </Section>
      <Section id="watch">
        <h2>Watch</h2>
        <LeadText>Couldn't make it to the Summit? You can watch recordings of the 2024 Conference and Summit below:</LeadText>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/videoseries?si=jdygiIHUn4Wvlq8B&amp;list=PLwTL48ihN7WZ-If-jW-wMFsU_8ZRwL_Yp"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowFullScreen
          title="YouTube video player"
          css={tw`my-10 max-w-full`}
        />
        <h2>Previous conferences and summits</h2>
        <LeadText>
          You can watch free recordings of previous summits and conferences on our website.      
          <ul>          
            <li><SafeLink to="/summit-2020/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Watch the 2020 summit &rarr;</SafeLink></li>
            <li><SafeLink to="/summit-conference-2021/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Watch the 2021 summit and conference &rarr;</SafeLink></li>
            <li><SafeLink to="/summit-conference-2022/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Watch the 2022 summit and conference &rarr;</SafeLink></li>
            <li><SafeLink to="/conference-summit-2023/" css={tw`border-b-2 border-maroon hover:text-maroon`}>Watch the 2023 summit and conference &rarr;</SafeLink></li>
            <li><SafeLink to="/downloads/events/2024-08-summit-compendium.pdf" css={tw`border-b-2 border-maroon hover:text-maroon`}>Download compendium of outcomes of previous summits &rarr;</SafeLink></li>
          </ul>
        </LeadText>           
      </Section>        
      <Section id="contact" isShaded>
        <h2>Contact us</h2>        
        <Grid mdGridCols={3} lgGridCols={3} lgGap={20}>
          <form action="https://usebasin.com/f/4925e0b01894" method="POST" className="col-span-2">
            <label class="block mb-5">
              <span class="text-dark">Your name:</span>
              <input
                class="form-input mt-1 block w-full"
                name="name"
                placeholder="Thando Mkhize"
              />
            </label>
            <label class="block mb-5">
              <span class="text-dark">Your email address:</span>
              <input
                class="form-input mt-1 block w-full"
                name="email"
                placeholder="thando@mkize.com"
              />
            </label>
            <label for="phone" css={tw`mb-5`}>
              <span css={tw`block`}>Your phone number:</span>
              <input
                name="phone"
                type="text"
                placeholder="+27 72 123 4567"
                className="form-input mt-1 block w-full"
              />
            </label>
            <label class="block mb-5">
              <span class="text-dark">Your message</span>
              <textarea
                class="form-textarea mt-1 block w-full"
                rows="3"
                name="message"
                placeholder="..."
              ></textarea>
            </label>         
            <Button type="submit" size="md">
              Submit &rarr;
            </Button>
          </form>                  
          <div>
              <p>Please contact the summit organisers Dr Marna Lourens and Ms Chiara Jeptha with any queries.</p> 
              <p><SafeLink to="mailto:mlourens@sun.ac.za">mlourens@sun.ac.za</SafeLink> | <SafeLink to="mailto:cjeptha@sun.ac.za">cjeptha@sun.ac.za</SafeLink></p>
              <p>+27(0) 21 808 3186</p>
              <p>Centre for Social Justice, Stellenbosch University, Old Main Building, Cnr of Ryneveld and Victoria Streets, Matieland, 7602</p> 
          </div>          
        </Grid>
      </Section>              
    </Layout>
  );
};

export default Summit2024;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "2024-08-summit-bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    summit2024Banner: file(relativePath: { eq: "2024-08-summit-poster.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }    
    site {
      siteMetadata {
        title
      }
    }
  }
`;
